<template>
  <v-app>
    <v-card width="98%" class="mx-auto mt-5 pa-5">
        <v-card-title class="justify-center">
        特定商取引法に基づく表記
        </v-card-title>
        <v-card-text>
                <p v-html="this.clinic.sct_html"></p>
        </v-card-text>
    </v-card>
  </v-app>
</template>


<script>

import clinic from '@/mixins/clinic'
export default {
    name: "Welcome",
    mixins: [clinic],
    created() {
        this.clinicFetchById()
    },
    computed: {
        clinic_id() {
            return this.$route.params.id
        },
        clinic(){
            return this.$store.state.clinicModule.data[this.clinic_id]
        },
    },
    methods: {
        clinicFetchById : async function(){
            try {
                await this.$store.dispatch('clinicModule/fetchById', this.clinic_id)
            } catch(e) {
                this.error = true
                //this.$router.push({ name: "error", params: { message:  "表示できません。" } })
            } finally {
                this.loading = false
            }
        },
    }
}
</script>
